<template>
  <div class="col-12">
    <div class="card">
      <h5>Ver Detalle Producto</h5>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <label for="">C&oacute;digo Producto:</label>
          <InputText type="text" disabled v-model="producto.id" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">C&oacute;digo de Barra:</label>
          <InputText type="text" disabled v-model="producto.codigo_barra " />
        </div>
        <div class="field col-12 md:col-6">
          <label for="">Nombre Producto:</label>
          <InputText type="text" disabled v-model="producto.descripcion" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">Código Laborario:</label>
          <InputText type="text" disabled v-model="producto.fabrica_id" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Nombre Laborario:</label>
          <InputText type="text" disabled v-model="producto.fabrica_nombre" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">Código Unidad de Medida:</label>
          <InputText type="text" disabled v-model="producto.unidadmedida_id" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Nombre Unidad de Medida:</label>
          <InputText type="text" disabled v-model="producto.unidad_medida_nombre" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">Stock M&iacute;nimo:</label>
          <InputText type="text" disabled v-model="producto.stock_minimo" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">Stock M&aacute;ximo:</label>
          <InputText type="text" disabled v-model="producto.stock_maximo" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">Fecha Vencimiento:</label>
          <Calendar type="text" disabled v-model="producto.fecha_vencimiento" />
        </div>
        <div class="field col-12 md:col-2">
          <label for="">Precio Sugerido:</label>
          <InputText type="text" disabled v-model="producto.precio_sugerido" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Estado:</label>
          <InputText type="text" disabled v-model="texto_estado" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">Fecha Creaci&oacute;n:</label>
          <InputText type="text" disabled v-model="producto.created_at" />
        </div>
        <div class="field col-12 md:col-3">
          <label for="">Fecha Actualizaci&oacute;n:</label>
          <InputText type="text" disabled v-model="producto.updated_at" />
        </div>
        <div class="field col-12">
          <label for="">Caso de Uso:</label>
          <Textarea type="text" disabled v-model="producto.caso_uso" />
        </div>
        <div class="field col-12 md:col-1">
          <Button @click="goBack">
            <i class="pi pi-arrow-circle-left"></i>
            &nbsp;ATR&Aacute;S
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductService from '@/service/ProductService';
export default {
  data() {
    return {
      producto: {},
      texto_estado: '',
      idProducto: this.$route.params.id,
    };
  },
  productoService: null,
  created() {
    this.productoService = new ProductService();
  },
  mounted() {
    this.productoService.showProducto(this.idProducto).then((data) => {
      this.producto = data;
      this.texto_estado = this.textoEstado(data.estado);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
  },
};
</script>